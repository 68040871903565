// Request
import { doRequest } from '@/utils/requestApi'

/**
 * Set the companyActivations by companyKeysId and save new status
 *
 * @param {string} companyKeysId - companyKeysId
 */
export async function updateCompanyActivationToDoneByCompanyKey(companyKeysId = null) {
  const result = await doRequest({
    method: 'patch',
    url: `v1/company-activation/company-key/${companyKeysId}`
  })

  return result.data
}
