// Constants
import {
  ACTIVATION_STATUS,
  ACTIVATION_TYPES,
  ADDONS,
  COMPANY_TYPES,
  PACKAGES,
  ROLES
} from '@/constants'
// Components
import BrandCardCentered from '@/components/ui/BrandCardCentered'
import FormButtons from '@/components/ui/FormButtons'
// Mixins
import formMixin from '@/mixins/formMixin'
import uiMixin from '@/mixins/uiMixin'
// Vuelidate plugin
import { validationMixin } from 'vuelidate'
import { required, minLength, email } from 'vuelidate/lib/validators'
// Services
import { getCompanyKeyById, updateCompanyKeyById } from '@/services/companyKeys'
import { updateCompanyActivationToDoneByCompanyKey } from '@/services/companyActivations'
// Vuex
import { mapActions } from 'vuex'
// Utils
import { getEnvironmentVariable } from '@/utils'

export default {
  name: 'RegisterByCompany',
  components: { BrandCardCentered, FormButtons },
  mixins: [validationMixin, formMixin, uiMixin],

  data() {
    return {
      // Form
      formFields: {
        companyKeysId: null,
        email: null,
        password: null
      },
      formFieldsValidations: {
        companyKeysId: {
          required: 'Campo obligatorio'
        },
        email: {
          required: 'Campo obligatorio',
          email: 'Formato incorrecto de email'
        },
        password: {
          required: 'Campo obligatorio',
          minLength: 'Debe contener al menos 6 caracteres'
        }
      },
      togglePassword: false // to show loading in action buttons
    }
  },
  computed: {
    /**
     * We need a computed option to pass an object with the differents
     * options about the vuetify buttons:
     *
     * https://vuetifyjs.com/en/components/buttons/#buttons
     */
    acceptButtonVOptions() {
      return {
        block: true,
        large: true
      }
    }
  },
  methods: {
    ...mapActions('authentication', ['registerNewUser']),
    /**
     * Is triggering after the form is correctly
     * validated by "Vuelidate"
     */
    async afterSubmit() {
      try {
        // Check if the code exists
        const companyKeysData = await getCompanyKeyById(this.formFields.companyKeysId)

        if (!companyKeysData || !companyKeysData.enabled) {
          throw new Error('Código de cliente erroneo o ya usado')
        }

        // Creamos usuario, compañía y establecimiento
        await this.registerNewUser({
          user: {
            brand: getEnvironmentVariable('VUE_APP_BRAND'),
            email: this.formFields.email,
            password: this.formFields.password,
            displayName: companyKeysData.companyName || null,
            role: ROLES.horeca_admin.value
          },
          userClaims: {
            role: ROLES.horeca_admin.value
          },
          place: {
            brand: getEnvironmentVariable('VUE_APP_BRAND'),
            name: companyKeysData.companyName,
            companyKeysId: this.formFields.companyKeysId
          },
          company: {
            brand: getEnvironmentVariable('VUE_APP_BRAND'),
            type: COMPANY_TYPES.horeca
          },
          addOns: [
            {
              packageId: PACKAGES.pro.value,
              subscriptionData: {
                amount: 0,
                currency: 'EUR',
                priceId: 'custom',
                period: 36500
              }
            }
          ],
          addOnConfigs: [
            {
              target: 'place',
              id: ADDONS.place,
              configFields: {
                name: companyKeysData.companyName
              }
            },
            {
              target: 'place',
              id: ADDONS.contact,
              configFields: {
                email: this.formFields.email
              }
            }
          ]
        })

        // Update the companyKeys
        await updateCompanyKeyById({
          activationType: ACTIVATION_TYPES.self,
          activationStatus: ACTIVATION_STATUS.done,
          id: this.formFields.companyKeysId,
          enabled: false
        })
        // Update the CompanyActivations
        await updateCompanyActivationToDoneByCompanyKey(this.formFields.companyKeysId)
      } catch (error) {
        // Show error
        this.modifyAppAlert({
          type: 'error',
          text: error.message,
          visible: true
        })
        // Cerramos diálogo de carga
        this.modifyAppLoadingLayer({
          visible: false
        })
        // Close session
        await this.closeSession()
      } finally {
        this.formProcessing = false
      }
    }
  },
  // Validations with Vuelidate
  validations: {
    formFields: {
      companyKeysId: {
        required
      },
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(6)
      }
    }
  }
}
